import { Flex } from "rebass";
import Graph from "components/Graph";
import Spinner from "components/Spinner";
import Health from "../components/Health";

export default function StatusView({ loading, error, healthData }) {
  const { latestStatistics, formatedData, timeOfError } = healthData || {};
  return (
    <Flex
      maxWidth="1140px"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      mx="auto"
      px="15px"
    >
      <Health latestStatistics={latestStatistics} timeOfError={timeOfError} />
      {loading || error ? (
        <Spinner />
      ) : (
        <Flex
          mb="48px"
          justifyContent="center"
          width="100%"
          sx={{ flexWrap: "wrap" }}
        >
          {formatedData?.map(({ data, service }, i) => {
            const correspondingStatistic = latestStatistics.filter(
              (serviceData) => serviceData.service === service
            )[0].data;

            return (
              <Flex
                justifyContent="center"
                width="33.33%"
                minWidth="358px"
                key={i}
              >
                <Graph
                  data={data}
                  service={service}
                  lastChecked={correspondingStatistic?.checked}
                  key={service}
                />
              </Flex>
            );
          })}
        </Flex>
      )}
    </Flex>
  );
}
